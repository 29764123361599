import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CartwheelButtonComponent, CartwheelIconButtonComponent } from '@cartwheel/web-components';
import { LogoutAction } from '../../../redux/actions/loginuser';
import { State } from '../../../redux/reducers';

@Component({
  standalone: true,
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  imports: [CartwheelButtonComponent, CartwheelIconButtonComponent]
})
export class LogoutComponent {
  constructor(
    private store: Store<State>,
    private dialog: MatDialogRef<LogoutComponent>
  ) { }

  public closeDialog() {
    this.dialog.close();
  }
  public signOut() {
    this.closeDialog();
    this.store.dispatch(new LogoutAction(null));
  }
}
