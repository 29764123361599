
import { Component, HostListener, EventEmitter, Inject } from '@angular/core';
import { State } from '../../../redux/reducers';
import { ImportExternalClientsAction } from '../../../redux/actions/ftux';
import { Store } from '@ngrx/store';
import { CartwheelButtonComponent } from '@cartwheel/web-components';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  standalone: true,
  selector: 'app-found-external-clients',
  templateUrl: './found-external-clients.component.html',
  styleUrls: ['./found-external-clients.component.scss'],
  imports: [CartwheelButtonComponent]
})
export class FoundExternalClientsComponent {
  serviceName: string;
  close = new EventEmitter<boolean>();

  constructor(private store: Store<State>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.serviceName = data.service;
  }


  public importClients() {
    this.store.dispatch(new ImportExternalClientsAction(this.serviceName));
    this.close.emit(true);
  }
  @HostListener('keydown.esc')
  public onEsc(): void {
    // this.close.emit();
  }
  public closeDialog() {
    this.close.emit(false);
  }
}
