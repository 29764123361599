export class UserProfile {
  public AuthToken: String;
  public firstName: String;
  public lastName: String;
  public emailAddress: String;
  public weekStart: number;
  public weekEnd: number;
  public globalBilledRate: number;
  public email: String;
}

export class UserIdentity {
  name: string;

  constructor(data: any) {
    if (data.name) {
      this.name = data.name;
    }
  }
}
