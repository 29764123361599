<div [class.slideIn]="currentSlide === 0" [class.slideOut]="currentSlide !== 0">
  <div class="header">
    <div>Pick a Client from {{ provider }}</div>
  </div>
  <div class="separator"></div>
  <div class="wrapper">
    <div class="scroll-wrapper">
      <mat-radio-group [(ngModel)]="clientToImport">
        @for (client of clients; track 'client' + $index) {
          <mat-radio-button color="primary" [value]="client">
            {{ client.clientName }}
          </mat-radio-button>
        }
      </mat-radio-group>
    </div>
    <div class="button-wrapper">
      <app-save-button
        [disabled]="false"
        [loading]="null"
        [text]="'Next'"
        (save)="goToCartwheelLinkSlide()">
      </app-save-button>
    </div>
  </div>
</div>
<div [class.slideIn]="currentSlide === 1" [class.slideOut]="currentSlide !== 1">
  <div>
    <div *ngIf="(loadingProjectsForImport$ | async) === NetworkStatus.IsLoading">
      <div class="header">
        <div>Loading Projects</div>
      </div>
      <mat-spinner single-color strokeWidth="3" diameter="25"></mat-spinner>
    </div>
    <div class="project-wrapper" *ngIf="(loadingProjectsForImport$ | async) !== NetworkStatus.IsLoading">
        <div class="header">
          <div>Any Cartwheel projects that aren't linked to a {{ provider }} task, won't have their time entries submitted to {{ provider }}</div>
        </div>
        <div class="separator"></div>
        <div class="scroll-wrapper">
          @for (project of cartwheelProjectLinkForm.get('projects').value; track 'project' + $index) {
            <div class="form-wrapper">
              <form [formGroup]="project">
                <span>{{ project.get('name').value }}</span>
                <mat-select formControlName="externalProjectId" placeholder="Actions">
                  <mat-option [value]="-1">Don't Link</mat-option>
                  @for (externalProject of externalImportProjectForm.get('projects').value; track 'external-project' + $index) {
                    <mat-option
                      [value]="externalProject.get('externalProjectId').value">
                        {{ externalProject.get('name').value }}
                    </mat-option>
                  }
                </mat-select>
              </form>
            </div>
          }
        </div>
        <div class="button-wrapper">
          <app-save-button
            [disabled]="false"
            [loading]="null"
            [text]="(singleCartwheelProjectToLink) ? 'Save' : 'Next'"
            (save)="(singleCartwheelProjectToLink) ? save() : goToProjectImportSlide()">
          </app-save-button>
          <div>
            <button mat-button (click)="goToClientChoiceSlide()">Back</button>
          </div>
        </div>
    </div>
  </div>
</div>
<div [class.slideIn]="currentSlide === 2" [class.slideOut]="currentSlide !== 2">
  <div>
    <div class="project-wrapper" *ngIf="(loadingProjectsForImport$ | async) !== NetworkStatus.IsLoading">
        <div class="header">
          <div>Would you like to link projects from</div>
          <div>{{ provider }} to Cartwheel or create new?</div>
        </div>
        <div class="separator"></div>
        <div class="scroll-wrapper">
          @for (project of externalImportProjectForm.get('projects').value; track 'external-import-project' + $index) {
            <div class="form-wrapper">
              <form [formGroup]="project">
                <span>{{ project.get('name').value }}</span>
                <mat-select formControlName="action" placeholder="Actions">
                  <mat-option [value]="projectImportAction.DontImport">Don't Import</mat-option>
                  <mat-option [value]="projectImportAction.Import">{{projectImportAction[projectImportAction.Import]}}</mat-option>
                </mat-select>
              </form>
            </div>
          }
        </div>
        <div class="button-wrapper">
          <app-save-button
            [disabled]="false"
            [loading]="savingProjectsToDB$ | async"
            [text]="'Save'"
            (save)="save()">
          </app-save-button>
          <div>
            <button mat-button (click)="goToClientChoiceSlide()">Back</button>
          </div>
        </div>
    </div>
  </div>
</div>
