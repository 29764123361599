import dayjs from 'dayjs';
import { from, timer as observableTimer, of as observableOf, Observable, of, zip } from 'rxjs';

import { take, timeInterval, map, pluck, skipWhile, switchMap, filter } from 'rxjs/operators';
import { SuccessfulAuth } from '../models/OAuthIdent';
import { getParameterByName } from '../util';
import { RegisterUser, ResendConfirmation } from '../models/registeruser';
import { User } from '../models/user';
import { Injectable, ViewContainerRef } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { WindowService } from './window.service';
import { LogoutComponent } from '../shared/dialogs/logout/logout.component';
import { TogglClientConnectComponent } from '../shared/dialogs/toggl/toggl-client-connection.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ChangePassword } from '../models/registeruser';

import { ExternalInvoicingSystems, AlertType, SuccessStatus, UserRole } from '../shared/enums';
import { UserCompanyRole } from '@cartwheel/web-components';
import { ExternalInvoiceService } from './external-invoice.service';
import { select, Store } from '@ngrx/store';
import { State, getFtuxProvidersState, getUserProfileRoles, getDashboardRole, getGSettingsGlobalSettingsModel } from 'app/redux/reducers';
import { UserIdentity } from 'app/models/user-profile';
import { CURRENT_ROLE } from 'app/shared/consts';
import { GetUserRolesAction } from 'app/redux/actions/loginuser';
import { ChangeCurrentRoleAction } from 'app/redux/actions/dashboard';
import { jwtDecode } from 'jwt-decode';
import { ExternalInvoice } from 'app/models/external-invoice';
import { Alert } from 'app/models/alerts';
import { SetExternalProviderStatusAction } from 'app/redux/actions/ftux';
import { IntegrationService } from './integration.service';
import { NgForage } from 'ngforage';

@Injectable()
export class AuthService {
  private viewRef: ViewContainerRef;
  _loggedIn = false;
  loggingIn = false;
  redirectUrl = '';
  dialogRef: MatDialogRef<TogglClientConnectComponent>;
  private popupWindow;
  private networkError;
  private genericError;
  private token: string;
  private logoutDiagRef: MatDialogRef<LogoutComponent>;
  // TODO: https://angular.io/guide/http#setting-new-headers
  constructor(
    private http: HttpClient,
    private windowService: WindowService,
    private dialogService: MatDialog,
    // TODO: comment this out, and use this instead https://stackoverflow.com/a/48288112/1103813\
    private lStorage: NgForage,
    private externalService: ExternalInvoiceService,
    private integrationService: IntegrationService,
    private store: Store<State>
  ) {
    const body = {
      error_description: 'No Internet Connection'
    };
    this.networkError = new HttpResponse({

      body: body,
      status: 503
    });
    const genericBody = { error_description: 'Login Error' }
    this.genericError = new HttpResponse({
      body: genericBody,
      status: 400
    });
  }

  public authenticated() {
    try {
      return this.loggedIn();
    } catch (error) {
      return false;
    }
  }
  public loggedIn(): boolean {
    if (this.token) {
      const decodedToken = jwtDecode(this.token);
      return !this.isTokenExpired(decodedToken.exp);
    }
    return false;
  }

  public getAuthToken(): string {
    return this.token;
  }

  public login(user: User) {
    // TODO: http://jasonwatmore.com/post/2016/08/16/angular-2-jwt-authentication-example-tutorial
    if (user) {
      const body = `grant_type=password&username=${encodeURIComponent(user.email)}&password=${encodeURIComponent(user.password)}`;
      const url = `${environment.server}/connect/token`;
      const headers = new HttpHeaders().append('content-type', 'application/x-www-form-urlencoded');
      return this.http.post(url, body, { headers: headers }).pipe(map(data => data));
    } else {
      return observableOf(this.genericError);
    }
  }

  public getUserRoles(): Observable<UserCompanyRole[]> {
    const url = `${environment.server}/Account/Roles`;
    return this.http.get<UserCompanyRole[]>(url);
  }

  private isTokenExpired(expiration: number): boolean {
    const diffBetweenNowandExpiration = dayjs().diff(expiration)
    return diffBetweenNowandExpiration < 0;
  }

  public checkForAlerts(): Observable<Alert[]> {
    return zip(
      this.store.select(getFtuxProvidersState),
      this.store.select(getUserProfileRoles).pipe(skipWhile(r => !r)),
      this.store.select(getDashboardRole).pipe(skipWhile(r => !r))
    ).pipe(
      skipWhile(p => !p),
      take(1),
      filter(([ps, r, cr]) => cr.userRole === UserRole.User),
      switchMap(([providerState, roles, currentRole]) => {
        const alerts = roles
          .filter(role => role.providers)
          .map(role => {
            return role.providers.map(e =>
              new Alert({
                name: role.companyName,
                type: AlertType.ApplicationConnection,
                providerType: e,
                companyId: role.companyId,
              }))
          })
          .reduce((sum, a) => sum.concat(a), [])
          .filter(a => providerState[a.providerType]?.connectionStatus !== SuccessStatus.Success);
        return of(
          Array.from(new Set(alerts.map(a => a.providerType))).map(p => alerts.find(a => a.providerType === p)) as Alert[]
        );
      })
    );
  }

  public setUserProfile(): any {
    const subscription = from(this.lStorage.getItem('token')).subscribe((newToken: string) => {
      this.token = newToken;

      const token = jwtDecode(this.token);
      const userProfile = (token) ? new UserIdentity(token) : null;

      if (userProfile) {
        from(this.lStorage.getItem(CURRENT_ROLE)).subscribe((localRole: string) => {
          if (localRole) {
            this.store.dispatch(new ChangeCurrentRoleAction(JSON.parse(localRole)))
          }
        });

        this.store.dispatch(new GetUserRolesAction());

        this.integrationService.setUser(userProfile.name);
      }
      subscription.unsubscribe();
    });
  }

  public checkConfirmation(confCode: string): Observable<RegisterUser> {
    const url = `${environment.server}/Account/ConfirmAccount?confirmationCode=${encodeURIComponent(confCode)}`;
    return this.http.post<RegisterUser>(url, null);
  }

  public captureLead(email: string): Observable<string> {
    if (email) {
      const url = `${environment.server}/Account/CaptureLead?email=${encodeURIComponent(email)}`;
      return this.http.post(url, null, { responseType: 'text' });
    }
  }

  public resendConfirmation(confirmationInfo: ResendConfirmation) {
    if (confirmationInfo) {
      const url =
        `${environment.server}/Account/ResendConfirmation?` +
        `Email=${encodeURIComponent(confirmationInfo.email)}&ConfirmationCode=${encodeURIComponent(confirmationInfo.confirmationCode)}`;
      return this.http.post(url, {}, { responseType: 'text' });
    }
  }

  public registerUser(user: RegisterUser) {
    const url = `${environment.server}/Account/Register`;
    // Friendly reminder, with ASP.net, you need the ```[FromBody]``` attribute to use
    // JSON.stringify() to pass the data to the server side
    return this.http.post(url, JSON.stringify(user), { responseType: 'text' });
  }

  public registerExternalUser(user: RegisterUser) {
    const url = `${environment.server}/Account/RegisterExternal`;
    return this.http.post<SuccessfulAuth>(url, JSON.stringify(user)).pipe(map(data => data));
  }

  public changePasswordUser(user: ChangePassword) {
    const url = `${environment.server}/Home`;
    //  post = Submits data to be processed to a specified resource, but in this
    // case what is the resource? Should I throw in the password? password=user
    return this.http.post<string>(url, JSON.stringify(user)).pipe(map(data => data));
  }

  public openTogglLogin(provider: ExternalInvoice): Observable<any> {
    return new Observable(ob => {
      const config = new MatDialogConfig();
      config.width = '500px';
      config.maxWidth = '80vw';
      config.hasBackdrop = true;
      config.data = { provider };
      config.disableClose = true;

      this.dialogRef = this.dialogService.open(TogglClientConnectComponent, config);
    });
  }

  public closeTogglDialog(close: boolean, billComTeamProvider = false): Observable<any> {
    if (billComTeamProvider) {
      this.store.pipe(select(getGSettingsGlobalSettingsModel)).subscribe(settings => {
        settings.externalInvoicingSystems.forEach(provider => {
          this.store.dispatch(new SetExternalProviderStatusAction({
            provider: provider,
            status: SuccessStatus.Success
          }));
        });
      });
    }
    return new Observable(ob => {
      if (this.dialogRef && close) {
        this.dialogRef.close();
      }
    });
  }

  public harvestLogin(): Observable<any> {
    const me = this;
    const url = this.externalService.buildAuthUrl(ExternalInvoicingSystems[ExternalInvoicingSystems.Harvest], null);
    this.popupWindow = this.windowService.createWindow(url);
    return new Observable((ob) => {
      const timer = observableTimer(0, 100).pipe(
        timeInterval(),
        pluck('interval'),
        take(450));
      const sub = timer.subscribe(
        timerTick => {
          let href: string;
          try {
            href = me.popupWindow.location.href;
          } catch (e) { }

          if (href != null) {
            const re = /code=(.*)/;
            const errorre = /error=(.*)/;

            const found = href.match(re);
            const errorFound = href.match(errorre);
            if (found) {
              const token = getParameterByName('code', href);
              if (token) {
                // this means we authenticated
                ob.next(token);
                this.popupWindow.close();
                ob.complete();
              } else {
                ob.error({
                  error: 'This application wasn\'t authorized by the provider'
                });
                this.popupWindow.close();
                ob.complete();
                sub.unsubscribe();
              }
            }

            if (errorFound) {
              ob.error({
                error: 'This application wasn\'t authorized by the provider'
              });
              this.popupWindow.close();
              ob.complete();
              sub.unsubscribe();
            }
          }
          if (this.popupWindow?.closed) {
            ob.error({
              error: 'This application wasn\'t authorized by the provider'
            });
            ob.complete();
            sub.unsubscribe();
          }
        },
        err => {
          ob.error(err);
        },
        () => {
          if (this.popupWindow && !this.popupWindow.closed) {
            ob.error('Response from harvest timed out. Please try again');
            this.popupWindow.close();
            sub.unsubscribe();
          }
        }
      );
    });
  }

  public GoogleVerify(accessToken: string): Observable<SuccessfulAuth> {
    const url = `${environment.server}/Account/ReturnValidGoogleUser?accessToken=${accessToken}`;
    return this.http.get<SuccessfulAuth>(url);
  }

  public GoogleRegistrationVerification(accessToken: string): Observable<any> {
    const url = `${environment.server}/Account/ValidateExternalSignup`;
    return this.http.post<RegisterUser>(url, JSON.stringify({ Provider: 'google', ExternalAccessToken: accessToken }));
  }

  public logout() {
    if (this.token) {
      this.token = null;
      this.lStorage.clear();
    }
    return of(true);
  }

  public logoutNoObservable() {
    this.lStorage.clear();
  }
  public showLogOutDialog(): Observable<any> {
    return of(
      this.dialogService.open(LogoutComponent, {
        width: '544px',
        autoFocus: false
      })
    );
  }
}
