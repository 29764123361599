import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject } from '@angular/core';
import * as lf from 'localforage';

/** @internal */
class CachedItemImpl {
  data;
  expires;
  hasData;
  constructor(data, expiryTime) {
    this.data = data;
    this.expires = new Date(typeof expiryTime === 'number' ? expiryTime : 0);
    this.hasData = data != null;
  }
  get expired() {
    return this.expiresIn === 0;
  }
  get expiresIn() {
    return Math.max(0, this.expires.getTime() - Date.now());
  }
  toJSON() {
    return {
      data: this.data,
      expired: this.expired,
      expires: this.expires,
      expiresIn: this.expiresIn,
      hasData: this.hasData
    };
  }
  toString() {
    return JSON.stringify(this.toJSON());
  }
}

/** @internal */
const localForage = 'defineDriver' in lf ? lf : lf.default;

/** ngforage abstraction over localforage driver names */
var Driver;
(function (Driver) {
  /** The IndexedDB driver */
  Driver[Driver["INDEXED_DB"] = localForage.INDEXEDDB] = "INDEXED_DB";
  /** The localStorage driver */
  Driver[Driver["LOCAL_STORAGE"] = localForage.LOCALSTORAGE] = "LOCAL_STORAGE";
  /** The WebSQL driver */
  Driver[Driver["WEB_SQL"] = localForage.WEBSQL] = "WEB_SQL";
})(Driver || (Driver = {}));
// Clean up after Typescript's two-way enum transpiling
for (const d of [localForage.INDEXEDDB, localForage.LOCALSTORAGE, localForage.WEBSQL]) {
  delete Driver[d];
}
Object.freeze(Driver);

/** Default ngforage configuration */
const DEFAULT_CONFIG = new InjectionToken('Default NgForage config');

/** @internal */
const $defaultConfig = Symbol('Default Config');
/**
 * Global/default configuration
 */
class NgForageConfig {
  /** @internal */
  [$defaultConfig];
  constructor(conf) {
    this[$defaultConfig] = {
      cacheTime: 300000,
      description: '',
      driver: [Driver.INDEXED_DB, Driver.WEB_SQL, Driver.LOCAL_STORAGE],
      name: 'ngForage',
      size: 4980736,
      storeName: 'ng_forage',
      version: 1
    };
    if (conf) {
      this.configure(conf);
    }
  }
  /**
   * Cache time in milliseconds
   * @default 300000
   */
  get cacheTime() {
    return this[$defaultConfig].cacheTime;
  }
  set cacheTime(t) {
    this[$defaultConfig].cacheTime = t;
  }
  /**
   * Get the compiled configuration
   */
  get config() {
    return {
      cacheTime: this.cacheTime,
      description: this.description,
      driver: this.driver,
      name: this.name,
      size: this.size,
      storeName: this.storeName,
      version: this.version
    };
  }
  /**
   * A description of the database, essentially for developer usage.
   * @default
   */
  get description() {
    return this[$defaultConfig].description;
  }
  set description(v) {
    this[$defaultConfig].description = v;
  }
  /**
   * The preferred driver(s) to use.
   */
  get driver() {
    const d = this[$defaultConfig].driver;
    if (!d) {
      return [];
    } else if (Array.isArray(d)) {
      return d.slice();
    }
    return d;
  }
  set driver(v) {
    this[$defaultConfig].driver = v;
  }
  /**
   * The name of the database. May appear during storage limit prompts. Useful to use the name of your app here.
   * In localStorage, this is used as a key prefix for all keys stored in localStorage.
   * @default ngForage
   */
  get name() {
    return this[$defaultConfig].name;
  }
  set name(v) {
    this[$defaultConfig].name = v;
  }
  /**
   * The size of the database in bytes. Used only in WebSQL for now.
   * @default 4980736
   */
  get size() {
    return this[$defaultConfig].size;
  }
  set size(v) {
    this[$defaultConfig].size = v;
  }
  /**
   * The name of the datastore.
   * In IndexedDB this is the dataStore,
   * in WebSQL this is the name of the key/value table in the database.
   * Must be alphanumeric, with underscores.
   * Any non-alphanumeric characters will be converted to underscores.
   * @default ng_forage
   */
  get storeName() {
    return this[$defaultConfig].storeName;
  }
  set storeName(v) {
    this[$defaultConfig].storeName = v;
  }
  /**
   * The version of your database. May be used for upgrades in the future; currently unused.
   * @default 1.0
   */
  get version() {
    return this[$defaultConfig].version;
  }
  set version(v) {
    this[$defaultConfig].version = v;
  }
  /**
   * Bulk-set configuration options
   * @param opts The configuration
   */
  configure(opts) {
    const resolved = {
      ...opts
    };
    if (Array.isArray(resolved?.driver)) {
      resolved.driver = resolved.driver.slice();
    }
    Object.assign(this[$defaultConfig], resolved);
    return this;
  }
  /**
   * Define a driver
   *
   * You’ll want to make sure you accept a callback argument and that you pass the same arguments to callbacks as the
   * default drivers do. You’ll also want to resolve or reject promises.
   * Check any of the {@link https://github.com/mozilla/localForage/tree/master/src/drivers default drivers}
   * for an idea of how to implement your own, custom driver.
   * @param spec Driver spec
   */
  async defineDriver(spec) {
    return await localForage.defineDriver(spec);
  }
  /** @internal */
  toJSON() {
    return this.config;
  }
  toString() {
    return JSON.stringify(this.toJSON());
  }
  static ɵfac = function NgForageConfig_Factory(t) {
    return new (t || NgForageConfig)(i0.ɵɵinject(DEFAULT_CONFIG, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NgForageConfig,
    factory: NgForageConfig.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgForageConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [DEFAULT_CONFIG]
    }]
  }], null);
})();

/** @internal */
const stores = new Map();
/** @internal */
function getDriverString(driver) {
  if (!driver) {
    return '';
  } else if (Array.isArray(driver)) {
    return driver.slice().sort().join(',');
  } else {
    return driver;
  }
}
/** @internal */
function getHash(cfg) {
  return [getDriverString(cfg.driver), cfg.name, cfg.size, cfg.storeName, cfg.version, cfg.description, cfg.cacheTime].join('|');
}
/** @internal */
const conf$$1 = Symbol('Config');
/**
 * Creates localForage instances
 */
class InstanceFactory {
  /** @internal */
  [conf$$1];
  constructor(conf) {
    this[conf$$1] = conf;
  }
  getInstance(cfg) {
    const resolvedCfg = {
      ...this[conf$$1].config,
      ...cfg
    };
    const hash = getHash(resolvedCfg);
    const existing = stores.get(hash);
    if (existing) {
      return existing;
    }
    const nu = localForage.createInstance(resolvedCfg);
    const origDropInstance = nu.dropInstance;
    nu.dropInstance = function () {
      stores.delete(hash);
      return origDropInstance.apply(this, arguments);
    };
    stores.set(hash, nu);
    return nu;
  }
  static ɵfac = function InstanceFactory_Factory(t) {
    return new (t || InstanceFactory)(i0.ɵɵinject(NgForageConfig));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: InstanceFactory,
    factory: InstanceFactory.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InstanceFactory, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: NgForageConfig
  }], null);
})();

/** @internal */
const store$ = Symbol('Store');
/**
 * Abstract service-level configuration layer for NgForage
 */
class BaseConfigurableImpl {
  /** @internal */
  baseConfig;
  /** @internal */
  config = {};
  /** @internal */
  fact;
  /** @internal */
  storeNeedsRecalc = true;
  /** @internal */
  [store$];
  /** @internal */
  constructor(config, instanceFactory) {
    this.baseConfig = config;
    this.fact = instanceFactory;
  }
  /**
   * A description of the database, essentially for developer usage.
   * @default ""
   */
  get description() {
    return this.config.description || this.baseConfig.description;
  }
  set description(v) {
    this.config.description = v;
    this.storeNeedsRecalc = true;
  }
  /**
   * The preferred driver(s) to use.
   * @default IndexedDB, WebSQL and localStorage
   */
  get driver() {
    return this.config.driver ?? this.baseConfig.driver;
  }
  set driver(v) {
    this.config.driver = v;
    this.storeNeedsRecalc = true;
  }
  /**
   * The name of the database. May appear during storage limit prompts. Useful to use the name of your app here.
   * In localStorage, this is used as a key prefix for all keys stored in localStorage.
   * @default ngForage
   */
  get name() {
    return this.config.name || this.baseConfig.name;
  }
  set name(v) {
    this.config.name = v;
    this.storeNeedsRecalc = true;
  }
  /**
   * The size of the database in bytes. Used only in WebSQL for now.
   * @default 4980736
   */
  get size() {
    return this.config.size ?? this.baseConfig.size;
  }
  set size(v) {
    this.config.size = v;
    this.storeNeedsRecalc = true;
  }
  /**
   * The name of the datastore.
   * In IndexedDB this is the dataStore,
   * in WebSQL this is the name of the key/value table in the database.
   * Must be alphanumeric, with underscores.
   * Any non-alphanumeric characters will be converted to underscores.
   * @default ng_forage
   */
  get storeName() {
    return this.config.storeName ?? this.baseConfig.storeName;
  }
  set storeName(v) {
    this.config.storeName = v;
    this.storeNeedsRecalc = true;
  }
  /**
   * The version of your database. May be used for upgrades in the future; currently unused.
   * @default 1.0
   */
  get version() {
    return this.config?.version ?? this.baseConfig.version;
  }
  set version(v) {
    this.config.version = v;
    this.storeNeedsRecalc = true;
  }
  /** @internal */
  get finalConfig() {
    return {
      ...this.baseConfig.config,
      ...this.config
    };
  }
  /** @internal */
  get store() {
    if (this.storeNeedsRecalc || !this[store$]) {
      this[store$] = this.fact.getInstance(this.finalConfig);
      this.storeNeedsRecalc = false;
    }
    return this[store$];
  }
  /**
   * Bulk-set configuration options
   * @param opts The configuration
   */
  configure(opts) {
    opts = opts || {};
    if (Array.isArray(opts.driver)) {
      opts.driver = opts.driver.slice();
    }
    Object.assign(this.config, opts);
    this.storeNeedsRecalc = true;
    return this;
  }
  toJSON() {
    return {
      description: this.description,
      driver: this.driver,
      name: this.name,
      size: this.size,
      storeName: this.storeName,
      version: this.version
    };
  }
  toString() {
    return JSON.stringify(this.toJSON());
  }
  static ɵfac = function BaseConfigurableImpl_Factory(t) {
    return new (t || BaseConfigurableImpl)(i0.ɵɵinject(NgForageConfig), i0.ɵɵinject(InstanceFactory));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BaseConfigurableImpl,
    factory: BaseConfigurableImpl.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseConfigurableImpl, [{
    type: Injectable
  }], () => [{
    type: NgForageConfig,
    decorators: [{
      type: Inject,
      args: [NgForageConfig]
    }]
  }, {
    type: InstanceFactory,
    decorators: [{
      type: Inject,
      args: [InstanceFactory]
    }]
  }], null);
})();

/**
 * Cache instance
 */
class NgForage extends BaseConfigurableImpl {
  /**
   * Returns the name of the driver being used, or null if none can be used.
   */
  get activeDriver() {
    return this.store.driver();
  }
  /**
   * When invoked with no arguments, it drops the “store” of the current instance. When invoked with an object
   * specifying both name and storeName properties, it drops the specified “store”. When invoked with an object
   * specifying only a name property, it drops the specified “database” (and all its stores).
   */
  async dropInstance(cfg) {
    return await (cfg ? this.store.dropInstance(cfg) : this.store.dropInstance());
  }
  /**
   * Removes every key from the database, returning it to a blank slate.
   *
   * clear() will remove <b>every item in the offline store</b>. Use this method with caution.
   */
  async clear() {
    return await this.store.clear();
  }
  /**
   * Make a clone of the instance
   * @param config Optional configuration
   */
  clone(config) {
    const inst = new NgForage(this.baseConfig, this.fact);
    inst.configure({
      ...this.finalConfig,
      ...config
    });
    return inst;
  }
  /**
   * Gets an item from the storage library.
   * If the key does not exist, getItem() will return null.
   * @param key Data key
   */
  async getItem(key) {
    return await this.store.getItem(key);
  }
  /**
   * Iterate over all value/key pairs in datastore.
   * <i>iteratee</i> is called once for each pair, with the following arguments:
   * <ol>
   *   <li>Value</li>
   *   <li>Key</li>
   *   <li>iterationNumber - one-based number</li>
   * </ol>
   * iterate() supports early exit by returning a non-`undefined` value inside iteratorCallback callback.
   * @param iteratee
   */
  async iterate(iteratee) {
    return await this.store.iterate(iteratee);
  }
  /**
   * Get the name of a key based on its ID.
   * @param index
   */
  async key(index) {
    return await this.store.key(index);
  }
  /**
   * Get the list of all keys in the datastore.
   */
  async keys() {
    return await this.store.keys();
  }
  /**
   * Gets the number of keys in the offline store (i.e. its “length”).
   */
  async length() {
    return await this.store.length();
  }
  /**
   * Even though localForage queues up all of its data API method calls,
   * ready() provides a way to determine whether the asynchronous driver initialization process has finished.
   * That’s useful in cases like when we want to know which driver localForage has settled down using.
   */
  async ready() {
    return await this.store.ready();
  }
  /**
   * Removes the value of a key from the offline store.
   * @param key Data key
   */
  async removeItem(key) {
    return await this.store.removeItem(key);
  }
  /**
   * Saves data to an offline store. You can store the following types of JavaScript objects:
   * <ul>
   *  <li>Array</li>
   *  <li>ArrayBuffer</li>
   *  <li>Blob</li>
   *  <li>Float32Array</li>
   *  <li>Float64Array</li>
   *  <li>Int8Array</li>
   *  <li>Int16Array</li>
   *  <li>Int32Array</li>
   *  <li>Number</li>
   *  <li>Object</li>
   *  <li>Uint8Array</li>
   *  <li>Uint8ClampedArray</li>
   *  <li>Uint16Array</li>
   *  <li>Uint32Array</li>
   *  <li>String</li>
   * </ul>
   * @param key Data key
   * @param data Data
   */
  async setItem(key, data) {
    return await this.store.setItem(key, data);
  }
  /**
   * Check whether the given driver is supported/registered.
   * @param driver Driver name
   */
  supports(driver) {
    return this.store.supports(driver);
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵNgForage_BaseFactory;
    return function NgForage_Factory(t) {
      return (ɵNgForage_BaseFactory || (ɵNgForage_BaseFactory = i0.ɵɵgetInheritedFactory(NgForage)))(t || NgForage);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NgForage,
    factory: NgForage.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgForage, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/** @internal */
function calculateCacheKeys(mainKey) {
  return {
    data: `${mainKey}_data`,
    expiry: `${mainKey}_expiry`
  };
}
/**
 * An extension of {@link NgForage} which adds expiration support
 */
class NgForageCache extends NgForage {
  /**
   * Cache time in milliseconds
   * @default 300000
   */
  get cacheTime() {
    return this.config.cacheTime ?? this.baseConfig.cacheTime;
  }
  set cacheTime(t) {
    this.config.cacheTime = t;
    this.storeNeedsRecalc = true;
  }
  /** @inheritDoc */
  clone(config) {
    const inst = new NgForageCache(this.baseConfig, this.fact);
    inst.configure({
      ...this.finalConfig,
      ...config
    });
    return inst;
  }
  /**
   * Retrieve data
   * @param key Data key
   */
  async getCached(key) {
    const keys = calculateCacheKeys(key);
    const [data, expiry] = await Promise.all([this.getItem(keys.data), this.getItem(keys.expiry)]);
    return new CachedItemImpl(data, expiry);
  }
  /**
   * Remove data
   * @param key Data key
   */
  async removeCached(key) {
    const keys = calculateCacheKeys(key);
    await Promise.all([this.removeItem(keys.data), this.removeItem(keys.expiry)]);
  }
  /**
   * Set data
   * @param key Data key
   * @param data Data to set
   * @param [cacheTime] Override cache set in {@link CacheConfigurable#cacheTime global or instance config}.
   */
  async setCached(key, data, cacheTime = this.cacheTime) {
    const keys = calculateCacheKeys(key);
    const [out] = await Promise.all([this.setItem(keys.data, data), this.setItem(keys.expiry, Date.now() + cacheTime)]);
    return out;
  }
  /** @internal */
  toJSON() {
    return Object.assign(super.toJSON(), {
      cacheTime: this.cacheTime
    });
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵNgForageCache_BaseFactory;
    return function NgForageCache_Factory(t) {
      return (ɵNgForageCache_BaseFactory || (ɵNgForageCache_BaseFactory = i0.ɵɵgetInheritedFactory(NgForageCache)))(t || NgForageCache);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NgForageCache,
    factory: NgForageCache.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgForageCache, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/** @internal */
class NgForageCacheDedicated extends NgForageCache {
  /** @inheritDoc */
  clone(config) {
    const inst = new NgForageCacheDedicated(this.baseConfig, this.fact);
    inst.configure({
      ...this.finalConfig,
      ...config
    });
    return inst;
  }
}

/** @internal */
class NgForageDedicated extends NgForage {
  /** @inheritDoc */
  clone(config) {
    const inst = new NgForageDedicated(this.baseConfig, this.fact);
    inst.configure({
      ...this.finalConfig,
      ...config
    });
    return inst;
  }
}

/** @internal */
const conf$ = Symbol('NgForageConfig');
/** @internal */
const if$ = Symbol('InstanceFactory');
class DedicatedInstanceFactory {
  /** @internal */
  [conf$];
  /** @internal */
  [if$];
  constructor(conf, instFact) {
    this[conf$] = conf;
    this[if$] = instFact;
  }
  createCache(config) {
    const inst = new NgForageCacheDedicated(this[conf$], this[if$]);
    if (config) {
      inst.configure(config);
    }
    return inst;
  }
  createNgForage(config) {
    const inst = new NgForageDedicated(this[conf$], this[if$]);
    if (config) {
      inst.configure(config);
    }
    return inst;
  }
  static ɵfac = function DedicatedInstanceFactory_Factory(t) {
    return new (t || DedicatedInstanceFactory)(i0.ɵɵinject(NgForageConfig), i0.ɵɵinject(InstanceFactory));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DedicatedInstanceFactory,
    factory: DedicatedInstanceFactory.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DedicatedInstanceFactory, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: NgForageConfig
  }, {
    type: InstanceFactory
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BaseConfigurableImpl, CachedItemImpl, DEFAULT_CONFIG, DedicatedInstanceFactory, Driver, InstanceFactory, NgForage, NgForageCache, NgForageCacheDedicated, NgForageConfig, NgForageDedicated };
