<div class="relative p-24 rounded">
  <div class="pt-10 text-center mb-30">
    <h3 class="font-bold text-18 phone:text-24 text-darkerGray">
      Your {{serviceName}} Data
    </h3>
    <h4 class="text-16 phone:text-20 text-darkGray">
      We found clients in your {{serviceName}} account. <br>
      Would you like to import them now?
    </h4>
  </div>
  <div class="flex justify-center gap-10">
    <cartwheel-button
      class="w-full max-w-150"
      type="flat-button"
      color="secondary"
      label="Import"
      (onClick)="importClients()"
    />
    <cartwheel-button
      class="w-full max-w-150"
      type="text-button"
      color="secondary"
      label="No, thanks"
      (onClick)="closeDialog()"
    />
  </div>
</div>