<div>
  <div class="header">
    <div>Which Toggl workspace do you want to connect?</div>
  </div>
  <div class="separator"></div>
  <div class="wrapper">
    <div class="scroll-wrapper">
      <mat-radio-group [(ngModel)]="selectedWorkspace">
        @for (w of data.workspaces; track 'workspace' + $index) {
          <mat-radio-button color="primary" [value]="w">
            {{ w.name }}
          </mat-radio-button>
        }
      </mat-radio-group>
    </div>
    <div class="button-wrapper">
      <app-save-button
        [disabled]="!selectedWorkspace"
        [loading]="loading$ | async"
        [text]="'Save'"
        (save)="save()">
      </app-save-button>
    </div>
  </div>
</div>
