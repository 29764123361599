import { getBaseUrl, parseUrlForObject } from '../util';
import { Observable, of, from, throwError, EMPTY } from 'rxjs';
import { environment } from '../../environments/environment';
import { ExternalInvoicingSystems, ApplicationTypes, UserRole, PasswordType } from '../shared/enums';
import { Injectable, ViewContainerRef, ApplicationRef } from '@angular/core';
import { OAuthIdent, OAuthRequest } from '../models/OAuthIdent';
import { FoundExternalClientsComponent } from '../shared/dialogs/found-external-clients/found-external-clients.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { getFtuxImportLoading, State, getAlertFlag, getDashboardRole } from '../redux/reducers';
import { FtuxCompleteAction } from '../redux/actions/ftux';
import { GenerateUserTokenAction } from '../redux/actions/ftux';
import { ExternalInvoice } from '../models/external-invoice';
import { Client, TogglClient, HarvestClient } from '../models/Client';
import { ClientsFromProviderComponent } from '../shared/dialogs/clients-from-provider/clients-from-provider.component'
import { ApproverChoiceCompleteAction } from '../redux/actions/approver';
import { Project } from '../models/project';
import { CompanyInvoice, CompanyInvoicePagedRequest } from '../models/company-invoice';
import { AddNewProjectSuccessful } from 'app/redux/actions/project';
import { take, switchMap, map } from 'rxjs/operators';
import { UserCompanyRole } from '@cartwheel/web-components';
import { CURRENT_ROLE, REDIRECT_PATH } from 'app/shared/consts';
import { PasswordAuthForm } from 'app/models/approver';
import { GoToDashboardAction } from 'app/redux/actions/dashboard';
import { BillComOrgDialogComponent } from 'app/shared/dialogs/billcom-org-dialog/billcom-org-dialog.component';
import { TogglWorkspaceDialogComponent } from 'app/shared/dialogs/toggl-workspace-dialog/toggl-workspace-dialog.component';
import { NgForage } from 'ngforage';
import { InvoiceRequest } from 'app/models/invoicerequest';
import { PagedResponse } from 'app/models/pagination';
import { GoToBulkImportAction } from 'app/redux/actions/settings';

@Injectable()
export class ExternalInvoiceService {
  private dialog: MatDialogRef<FoundExternalClientsComponent>;
  private providerDialog: MatDialogRef<ClientsFromProviderComponent>;
  private loading$: Observable<boolean>;
  private viewRef: ViewContainerRef;
  private alertFlag$: Observable<boolean>;
  private alertFlag: boolean;
  private baseUrl = `${environment.server}/ExternalService`;

  constructor(private appRef: ApplicationRef,
    private http: HttpClient,
    private dialogService: MatDialog,
    private store: Store<State>,
    private lStorage: NgForage,
  ) {

    this.loading$ = this.store.select(getFtuxImportLoading);
    this.loading$.pipe()
      .subscribe(loading => {
        if (loading) {
          this.dialog.close(false);
        }
      });

    this.alertFlag$ = this.store.select(getAlertFlag);
    this.alertFlag$.subscribe((flag) => {
      this.alertFlag = flag;
    });
  }

  public authorizeCartwheelForExternal(tokenObj: OAuthRequest, role: UserCompanyRole): Observable<any> {
    const serviceName = tokenObj.serviceName;
    const redirectPath = tokenObj.redirectPath;
    const url = this.buildAuthUrl(serviceName, redirectPath);

    let path = window.location.hash.substring(1);
    if (serviceName === ExternalInvoicingSystems[ExternalInvoicingSystems.Veem]) {
      path += '/Veem'
    }

    // check if type is Cartwheel, then make network call to API to generate Stripe URL

    if (serviceName === ExternalInvoicingSystems[ExternalInvoicingSystems.Cartwheel]) {
      const requestUrl = `${this.baseUrl}/GenerateStripeUrl?returnUrl=${encodeURIComponent(path)}`;
      // TODO: update her to pass the current user role to the request
      return this.http.post(requestUrl, role, { responseType: 'text' })
        .pipe(map((e: string) => {
          window.location.href = e;
          return new Observable((ob) => {
            ob.next(e);
            ob.complete();
          });
        }));
    } else {
      return from(this.lStorage.setItem(REDIRECT_PATH, path))
        .pipe(
          switchMap(success => {
            window.location.href = url;
            return new Observable((ob) => {
              ob.next(url);
              ob.complete();
            });
          })
        );
    }

  }

  public ImportExternalClients(serviceName: string): Observable<Client[]> {
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap((role) => {
        const url = `${this.baseUrl}/ImportExternalClients?serviceName=${serviceName}`;
        return this.http.post<Client[]>(url, role);
      })
    );
  }

  public resendInvoice(invoiceId: string) {
    return this.http.post(`${environment.server}/invoice/ResendInvoice&InvoiceId=${invoiceId}`, null);
  }

  public buildAuthUrl(serviceName: string, redirectPath?: string): string {
    const baseUri = getBaseUrl(window.location.href, 10);
    const path =
      (redirectPath) ?
        (redirectPath.toLowerCase().endsWith('/settings')
          || redirectPath.toLowerCase().endsWith('/ftux')) ? redirectPath : 'Home/Settings' : 'Home/ftux';

    switch (serviceName) {
      case ExternalInvoicingSystems[ExternalInvoicingSystems.QuickBooks]: {
        return `${environment.quickbooks.userAuthUrl}` +
          `?client_id=${environment.quickbooks.clientId}` +
          `&state=${encodeURIComponent(`token=${ExternalInvoicingSystems[ExternalInvoicingSystems.QuickBooks]}`)}` +
          `&scope=${encodeURIComponent('com.intuit.quickbooks.accounting')}` +
          `&response_type=code` +
          `&redirect_uri=${baseUri}/${path}`
      }
      case ExternalInvoicingSystems[ExternalInvoicingSystems.Xero]: {
        return `${environment.xero.userAuthUrl}` +
          `?response_type=code&client_id=${environment.xero.clientId}` +
          `&redirect_uri=${baseUri}/${path}` +
          `&scope=offline_access accounting.transactions accounting.contacts accounting.settings` +
          `&state=${ExternalInvoicingSystems[ExternalInvoicingSystems.Xero]}`
      }
      case ExternalInvoicingSystems[ExternalInvoicingSystems.FreshBooks]: {
        return `${environment.freshbooks.userAuthUrl}?client_id=` +
          `${environment.freshbooks.clientId}&response_type=code&redirect_uri=${baseUri}/${path}`;
      }
      case ExternalInvoicingSystems[ExternalInvoicingSystems.Harvest]: {
        return `${environment.harvest.userAuthUrl}?client_id=` +
          `${environment.harvest.clientId}&response_type=code&redirect_uri=${baseUri}/${path}`;
      }
      case ExternalInvoicingSystems[ExternalInvoicingSystems.Adp]: {
        return `${environment.adp.userAuthUrl}`
      }
      case ExternalInvoicingSystems[ExternalInvoicingSystems.Toggl]: {
        return `${environment.toggl.userAuthUrl}`
      }
      case ExternalInvoicingSystems[ExternalInvoicingSystems.Veem]: {
        return `${environment.veem.userAuthUrl}?client_id=` +
          `${environment.veem.clientId}&oauth=true&redirect_uri=${baseUri}/${path}/Veem&response_type=code`;
      }
    }
  }

  public disconnectProvider(provider: ExternalInvoice) {
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap((role) => {
        return this.http.post(`${this.baseUrl}/disconnectProvider?provider=${provider.name}`, role);
      })
    );
  }

  public getAuthStatus(): Observable<string[]> {
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap((role) => {
        const url = `${this.baseUrl}/getAuthStatus`;
        if (role) {
          return this.http.post<string[]>(url, JSON.stringify(role));
        }
        return from(this.lStorage.getItem(CURRENT_ROLE)).pipe(
          switchMap((localRole: string) => {
            const lr = JSON.parse(localRole);
            return this.http.post<string[]>(url, JSON.stringify(lr))
          })
        );
      })
    );
  }

  public submitAccessTokenToServer(tokenInfo: OAuthIdent): Observable<boolean> {
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap((role) => {
        const url = `${this.baseUrl}/GetAccessToken`;
        if (role) {
          return this.http.post<boolean>(url, JSON.stringify({ ...tokenInfo, role }));
        }
        return from(this.lStorage.getItem(CURRENT_ROLE)).pipe(
          switchMap((localRole: string) => {
            const lr = JSON.parse(localRole);
            return this.http.post<boolean>(url, JSON.stringify({ ...tokenInfo, role: lr }))
          })
        );
      })
    );
  }

  public authTokenExists(serviceName: string): boolean {
    const re = /oauth_token=(.*)/;
    return serviceName.match(re).length > 0;
  }

  public getRequestToken(tokenObj: OAuthRequest): Observable<any> {
    const baseUri = getBaseUrl(window.location.href, 10);
    const path = (tokenObj.redirectPath) ? tokenObj.redirectPath : 'Home/ftux';
    const url = `${this.baseUrl}/GetRequestToken?serviceName=${tokenObj.serviceName}&callbackUrl=${baseUri}/${path}`;
    return this.http.post(url, {});
  }

  public loadClientsFromProvider(application: ApplicationTypes): Observable<Client[]> {
    const url = `${this.baseUrl}/LoadClientsForApproverApplication?servicename=${ApplicationTypes[application]}`;
    return this.http.post<HarvestClient[] | TogglClient[]>(url, {});
  }

  public loadProjectsFromProvider(application: ApplicationTypes, client: Client): Observable<Project[]> {
    const url = `${this.baseUrl}/LoadProjectsForApproverApplication?servicename=${ApplicationTypes[application]}`;
    if (application === ApplicationTypes.Harvest) {
      return this.http.post<Project[]>(
        url, JSON.stringify(client)
      );
    }
    return of([]);
  }

  public passwordLogin(payload: PasswordAuthForm): Observable<any> {
    const userPass =
      (payload.passwordType === PasswordType.password) ?
        `${payload.email}:${payload.password}` :
        `${payload.password}:api_token`;

    if (ApplicationTypes[payload.provider]) {
      switch (ApplicationTypes[payload.provider]) {
        case ApplicationTypes.Toggl: {
          return this.store.select(getDashboardRole)
            .pipe(
              take(1),
              switchMap(role => {
                const body = {
                  username: (payload.passwordType === PasswordType.password) ? payload.email : payload.password,
                  password: (payload.passwordType === PasswordType.password) ? payload.password : 'api_token',
                  userId: role.userId,
                  companyId: role.companyId
                }
                return this.http.post(`${environment.server}/ExternalAuth/ConnectToggl`, body).pipe(
                  switchMap((results: { id: string, name: string }[]) => {
                    const url = `${environment.server}/ExternalAuth/SelectTogglWorkspace`;

                    if (results.length < 2) {
                      return this.http.post<string>(url, { ...body, orgId: results[0].id })
                    }

                    const config = new MatDialogConfig();
                    config.width = '530px';
                    config.maxWidth = '80vw';
                    config.maxHeight = '80vh';
                    config.hasBackdrop = true;
                    config.autoFocus = false;
                    config.disableClose = true;
                    config.data = { workspaces: results, body };
                    return new Observable(ob => {
                      const dialog = this.dialogService.open(TogglWorkspaceDialogComponent, config);
                      dialog.componentInstance.close.subscribe(() => {
                        dialog.close();
                      });
                      dialog.afterClosed().subscribe(() => {
                        ob.next();
                        ob.complete();
                      });
                    });
                  })
                )
              })
            )
        }
        default: {
          return throwError(`Provider ${payload.provider} is not configured for external login`);
        }
      }
    } else {
      switch (ExternalInvoicingSystems[payload.provider]) {
        case ExternalInvoicingSystems.Qwil: {
          const url = `${environment.server}/ExternalAuth/ConnectQwil`;
          return this.store.select(getDashboardRole)
            .pipe(
              take(1),
              switchMap(role => {
                const body = {
                  username: payload.email,
                  password: payload.password,
                  userId: role.userId,
                  companyId: role.companyId
                }
                return this.http.post(url, body);
              })
            );
        }
        case ExternalInvoicingSystems.BillCom: {
          return this.store.select(getDashboardRole)
            .pipe(
              take(1),
              switchMap(role => {
                const body = {
                  username: payload.email,
                  password: payload.password,
                  userId: role.userId,
                  companyId: role.companyId,
                  teamProvider: payload.billComTeamProvider,
                  role: role.userRole
                }

                let url = `${environment.server}/ExternalAuth/GetBillComOrgs`;

                return this.http.post(url, body).pipe(
                  switchMap((results: { orgId: string, orgName: string }[]) => {

                    if (results.length < 2) {
                      url = `${environment.server}/ExternalAuth/ConnectBillCom`;
                      return this.http.post<string>(url, { ...body, orgId: results[0].orgId })
                    }

                    const config = new MatDialogConfig();
                    config.width = '530px';
                    config.maxWidth = '80vw';
                    config.maxHeight = '80vh';
                    config.hasBackdrop = true;
                    config.autoFocus = false;
                    config.disableClose = true;
                    config.data = { orgs: results, body };

                    return new Observable(ob => {
                      const dialog = this.dialogService.open(BillComOrgDialogComponent, config);
                      dialog.componentInstance.close.subscribe(() => {
                        dialog.close();
                      });
                      dialog.afterClosed().subscribe(() => {
                        ob.next();
                        ob.complete();
                      });
                    });
                  })
                );
              })
            );
        }
        default: {
          return throwError(`Provider ${payload.provider} is not configured for external login`);
        }
      }
    }
  }

  public closeClientChoiceDialog() {
    this.providerDialog.close();
  }

  public showClientChoiceDialog(provider: ApplicationTypes, clients: Client[], project: Project = null): Observable<any> {
    this.viewRef = this.appRef.components[0].instance.vref;

    if (clients && clients.length > 0 && !this.alertFlag) {
      return new Observable(ob => {
        const config = new MatDialogConfig();
        config.width = '530px';
        config.maxWidth = '80vw';
        config.maxHeight = '80vh';
        config.hasBackdrop = true;
        config.viewContainerRef = this.viewRef;
        config.autoFocus = false;
        config.disableClose = true;
        config.data = {
          clients: clients,
          provider: provider,
          project
        };

        this.providerDialog = this.dialogService.open(ClientsFromProviderComponent, config);

        this.providerDialog
          .afterClosed()
          .subscribe(() => {
            this.store.dispatch(new ApproverChoiceCompleteAction(provider));
            this.store.dispatch(new AddNewProjectSuccessful());
          });

        this.providerDialog.componentInstance.close.subscribe(res => {
          this.providerDialog.close();
        });
      });
    }
    this.store.dispatch(new ApproverChoiceCompleteAction(provider));
    this.store.dispatch(new AddNewProjectSuccessful());
    return EMPTY;
  }

  public showClientImportDialog(service: string, result: boolean): Observable<boolean> {
    // prevent opening another dialog if one is already open
    if (this.dialog && this.dialog.getState() === MatDialogState.OPEN) {
      return of(false);
    }
    
    this.viewRef = this.appRef.components[0].instance.vref;
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap((role) => {
        if (role.userRole === UserRole.User || !result) {
          return of(false);
        }

        return new Observable<boolean>(ob => {
          const config: MatDialogConfig = {
            panelClass: 'cartwheel-base-dialog',
            hasBackdrop: true,
            viewContainerRef: this.viewRef,
            autoFocus: false,
            data: { service }
          }

          this.dialog = this.dialogService.open(FoundExternalClientsComponent, config);

          this.dialog
            .afterClosed()
            .subscribe(value => {
              if (value) {
                if (this.dialog.componentInstance.serviceName !== 'Veem') {
                  this.store.dispatch(new FtuxCompleteAction(value));
                  this.store.dispatch(GoToBulkImportAction());
                }
              }
              ob.next(true);
              ob.complete();
            });

          this.dialog.componentInstance.close.subscribe((value: boolean) => {
            this.dialog.close(value);
          });
        });
      })
    );
  }

  public storeInvoiceData(param: any, redirect: string) {
    const authInfo = new OAuthIdent(param);

    authInfo.oauth_token = param['code'];
    authInfo.provider =
      (param['state'] === ExternalInvoicingSystems[ExternalInvoicingSystems.Cartwheel])
        ? ExternalInvoicingSystems[ExternalInvoicingSystems.Cartwheel] :
        (redirect.includes('Veem')) ?
          ExternalInvoicingSystems[ExternalInvoicingSystems.Veem] :
          (param['state'] === ExternalInvoicingSystems[ExternalInvoicingSystems.Xero]) ?
            ExternalInvoicingSystems[ExternalInvoicingSystems.Xero] :
            (param['realmId']) ?
              ExternalInvoicingSystems[ExternalInvoicingSystems.QuickBooks] :
              ExternalInvoicingSystems[ExternalInvoicingSystems.FreshBooks];

    authInfo.redirectPath =
      (redirect.toLowerCase().includes('/settings') || redirect.toLowerCase().includes('/ftux')) ?
        redirect :
        (authInfo.provider === ExternalInvoicingSystems[ExternalInvoicingSystems.Veem]) ?
          'Home/Settings/Veem' :
          'Home/Settings';

    this.store.dispatch(new GenerateUserTokenAction(authInfo));
  }

  public getAllServerInvoices(payload: CompanyInvoicePagedRequest): Observable<PagedResponse<CompanyInvoice>> {
    const params = new HttpParams({
      fromObject: new CompanyInvoicePagedRequest(payload) as Record<string, any>
    });
    return this.http.get<PagedResponse<CompanyInvoice>>(
      `${environment.server}/Invoice/GetInvoices`,
      { params }
    );
  }

  public getAllLocalInvoices(): Observable<CompanyInvoice[]> {
    return of(new Array<CompanyInvoice>());
  }

  public parseAuthToken(href: string, serviceName: string, callback: string) {
    const parsedUrl = href.substr(href.indexOf(callback) + callback.length + 1);
    const parsedResponse = parseUrlForObject(parsedUrl);
    switch (serviceName) {
      case ExternalInvoicingSystems[ExternalInvoicingSystems.QuickBooks]: {
        return parsedResponse;
      }
    }
  }

  public markInvoicesAsPaid(role: UserCompanyRole, ids: string[]) {
    const url = `${environment.server}/Invoice/markinvoicesaspaid?companyId=${role.companyId}`;
    return this.http.post<CompanyInvoice>(url, ids);
  }

  public createInvoice(role: UserCompanyRole, request: InvoiceRequest) {
    const url = `${environment.server}/Invoice/CreateInvoice?companyId=${role.companyId}`;
    return this.http.post<InvoiceRequest>(url, request);
  }
}
