import { FormsModule } from '@angular/forms';
import { Component, Inject, EventEmitter, DestroyRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuccessStatus } from 'app/shared/enums';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UUID } from 'angular2-uuid';
import { MatRadioModule } from '@angular/material/radio';
import { AsyncPipe } from '@angular/common';
import { environment } from 'environments/environment';
import { SaveButtonComponent } from 'app/shared/components/save-button/save-button.component';

interface BillComOrgData {
  orgs: { orgId: string, orgName: string }[],
  body: {
    username: string;
    password: string;
    userId: UUID;
    companyId: UUID;
  }
  teamProvider: boolean;
}

@Component({
  standalone: true,
  selector: 'app-billcom-org-dialog',
  templateUrl: './billcom-org-dialog.component.html',
  styleUrls: ['./billcom-org-dialog.component.scss'],
  imports: [AsyncPipe, FormsModule, SaveButtonComponent, MatRadioModule]
})

export class BillComOrgDialogComponent {
  public selectedOrg: { orgId: string, orgName: string };
  public close = new EventEmitter<void>();
  public loading$ = new BehaviorSubject<SuccessStatus | boolean>(false);

  constructor(
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: BillComOrgData,
    private destroyRef: DestroyRef
  ) { }

  save() {
    this.loading$.next(SuccessStatus.IsLoading);

    const url = `${environment.server}/ExternalAuth/ConnectBillCom`;
    this.http.post<string>(url, { ...this.data.body, orgId: this.selectedOrg.orgId }).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(() => {
        this.loading$.next(SuccessStatus.Success);
        setTimeout(() => this.close.emit(), 1000);
      }),
      catchError(() => {
        this.loading$.next(SuccessStatus.Error);
        setTimeout(() => {
          this.selectedOrg = undefined;
          this.loading$.next(false);
        }, 2000);
        return EMPTY;
      })
    ).subscribe();
  }
}
