<div>
    <h2 mat-dialog-title><img [src]="data.provider.imageSrc" />Connection</h2>
</div>
<mat-card appearance="outlined">
  <form [formGroup]="form" novalidate>
    <div class="mdl-grid p-24-48">
      <div class="pb-10 mdl-cell mdl-cell--12-col">
        <label class="text-uppercase">{{ label }}</label>
      </div>
      <div class="pb-10 mdl-cell mdl-cell--12-col">
        <input class="mdl-textfield__input border-1" name="email" formControlName="email">
      </div>
      @if (allowTokenPasswordType) {
        <div class="pb-10 mdl-cell mdl-cell--12-col">
          <mat-radio-group formControlName="passwordType">
              <div class="pr-10 m-0 mdl-cell mdl-cell--6-col">
                <mat-radio-button class="form-label-value" color="primary" value="password">
                  Password
                </mat-radio-button>
              </div>
              <div class="pr-10 m-0 mdl-cell mdl-cell--6-col">
                <mat-radio-button class="form-label-value" color="primary" value="apiToken">
                  Api Token
                </mat-radio-button>
              </div>
          </mat-radio-group>
          @if (helpText) {
            <mat-icon [matTooltip]="helpText" matTooltipPosition="above" />
          }
        </div>
      }
      @if (!allowTokenPasswordType) {
        <div class="pb-10 mdl-cell mdl-cell--12-col">
          <label class="text-uppercase">Password</label>
        </div>
      }
      <div class="pb-10 mdl-cell mdl-cell--12-col">
        <input type="password" class="mdl-textfield__input border-1" name="password" formControlName="password">
      </div>
      <div class="row">
        <!-- loadingStatus value can be boolean or SuccessStatus, so false must be explicitly checked -->
        <app-save-button 
          [disabled]="(loadingStatus$ | async) !== false" 
          [loading]="loadingStatus$ | async"
          [text]="'Sign In'"
          (save)="connect()">
        </app-save-button>
        @if ((loadingStatus$ | async) === false) {
          <button type="button" (click)="closeDialog()" class="mdl-button mdl-js-button mdl-js-ripple-effect">
            Cancel
          </button>
        }
      </div>
    </div>
  </form>
</mat-card>
  