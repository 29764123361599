import { Component, OnInit, Inject, DestroyRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { MatCardModule } from '@angular/material/card';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { ApproverApplicationLoginAction } from '../../../redux/actions/approver';
import { State, getApproverLoadingStatus, getFtuxProvidersState } from '../../../redux/reducers/index';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PasswordType, ApplicationTypes, ExternalInvoicingSystems, SuccessStatus } from '../../../shared/enums';
import { ExternalInvoice } from 'app/models/external-invoice';
import { skipWhile } from 'rxjs/operators';
import { ExternalInvoiceProviderLoginAction } from 'app/redux/actions/ftux';
import { SaveButtonComponent } from 'app/shared/components/save-button/save-button.component';

@Component({
  standalone: true,
  selector: 'app-toggl',
  templateUrl: './toggl-client-connection.component.html',
  styleUrls: ['./toggl-client-connection.component.scss'],
  imports: [AsyncPipe, ReactiveFormsModule, SaveButtonComponent, MatCardModule, MatIconModule, MatRadioModule, MatTooltipModule]
})

export class TogglClientConnectComponent implements OnInit {
  helpText: string;
  form: UntypedFormGroup;
  applicationTypes = ApplicationTypes;

  loadingStatus$: BehaviorSubject<boolean | SuccessStatus> = new BehaviorSubject(null);
  allowTokenPasswordType = false;
  label = 'Email';

  constructor(
    private store: Store<State>,
    private destroyRef: DestroyRef,
    private dialog: MatDialogRef<TogglClientConnectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      billComTeamProvider: boolean,
      provider: ExternalInvoice
    }) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', Validators.required),
      passwordType: new UntypedFormControl(PasswordType.password)
    });

    if (ApplicationTypes[this.data.provider.name]) {

      if (ApplicationTypes[this.data.provider.name] === ApplicationTypes.Toggl) {
        this.helpText = `If you signed up for Toggl with Google login, you'll need
        to use the api token found at https://toggl.com/app/profile`;

        this.allowTokenPasswordType = true;
      }

      this.store.select(getApproverLoadingStatus)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(status =>
          this.loadingStatus$.next(status[ApplicationTypes[this.data.provider.name]])
        );

    } else {

      if (ExternalInvoicingSystems[this.data.provider.name] === ExternalInvoicingSystems.Qwil) {
        this.label = 'Username';
        this.form.setControl('email', new UntypedFormControl('', [Validators.required]));
      }

      this.store.select(getFtuxProvidersState)
        .pipe(skipWhile(p => !p), takeUntilDestroyed(this.destroyRef))
        .subscribe(status => {
          const provider = ExternalInvoicingSystems[this.data.provider.name] as ExternalInvoicingSystems;
          this.loadingStatus$.next(status[provider].connectionStatus);
        });
    }
  }

  /* When the user clicks 'no' on the dialog, merely close the dialog */
  public closeDialog() {
    this.dialog.close();
  }

  public connect() {
    const form = {
      provider: this.data.provider.name,
      email: encodeURIComponent(this.form.controls['email'].value),
      password: encodeURIComponent(this.form.controls['password'].value),
      passwordType: this.form.controls['passwordType'].value,
      billComTeamProvider: this.data.billComTeamProvider
    }

    if (ApplicationTypes[this.data.provider.name]) {
      this.store.dispatch(new ApproverApplicationLoginAction(form));
    } else {
      this.store.dispatch(new ExternalInvoiceProviderLoginAction(form));
    }
  }
}
