import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {
  popup: Window;

  constructor() {
  }

  public createWindow(url: string, name: string = 'Window',
    width: number = 500, height: number = 600, left: number = 0, top: number = 0) {
    if (url == null) {
      return null;
    }

    const options = `width=${width},height=${height},left=${left},top=${top}`;
    this.popup = window;
    return this.popup.open(url, name, options);
  }

}
