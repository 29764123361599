import { Component, Inject, EventEmitter, OnInit, DestroyRef } from '@angular/core';
import { State, getApproverProjectLoading, getProjectLoading } from '../../../redux/reducers';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { Client, HarvestClient, TogglClient } from '../../../models/Client';
import { ApplicationTypes, SuccessStatus, ProjectImportAction } from '../../../shared/enums';
import { Project } from '../../../models/project';
import { Observable } from 'rxjs';
import { getApproverProjects } from '../../../redux/reducers';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BatchAddProjectAction, BatchUpdateProjectAction } from '../../../redux/actions/project';
import { getSelectedClient, getProjectsList } from '../../../redux/reducers/index';
import { SaveButtonComponent } from 'app/shared/components/save-button/save-button.component';

@Component({
  standalone: true,
  selector: ' ',
  templateUrl: './clients-from-provider.component.html',
  styleUrls: ['./clients-from-provider.component.scss'],
  imports: [AsyncPipe, FormsModule, ReactiveFormsModule, SaveButtonComponent, MatProgressSpinnerModule, MatSelectModule, MatRadioModule, NgIf]
})

export class ClientsFromProviderComponent implements OnInit {

  public clients: Client[] | HarvestClient[] | TogglClient[];
  public clientToImport: Client;
  public selectedClient$: Observable<Client>;
  public selectedClient: Client;
  public provider: string;
  public loadingProjectsForImport$: Observable<SuccessStatus>;
  public loadingProjectsForImport: SuccessStatus;
  public loadingCartwheelProjects$: Observable<boolean | SuccessStatus>;
  public savingProjectsToDB$: Observable<boolean | SuccessStatus>;
  public projects$: Observable<Project[]>;
  public cartwheelProjects$: Observable<Project[]>;
  public cartwheelProjects: Project[];
  public externalImportProjectForm: UntypedFormGroup;
  public cartwheelProjectLinkForm: UntypedFormGroup;
  public singleCartwheelProjectToLink: Project;

  public NetworkStatus = SuccessStatus;
  public close = new EventEmitter<void>();
  public currentSlide = 0;
  public projectImportAction = ProjectImportAction;

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private destroyRef: DestroyRef
  ) {
    this.clients = data.clients;
    this.provider = ApplicationTypes[data.provider];
    this.externalImportProjectForm = this.fb.group({
      projects: new Array<UntypedFormGroup>()
    });
    this.cartwheelProjectLinkForm = this.fb.group({
      projects: new Array<UntypedFormGroup>()
    });

    if (data.project) {
      this.singleCartwheelProjectToLink = data.project;
    }
  }

  ngOnInit() {
    this.projects$ = this.store.select(getApproverProjects);
    this.cartwheelProjects$ = this.store.select(getProjectsList);
    this.loadingCartwheelProjects$ = this.store.select(getProjectLoading);
    this.loadingProjectsForImport$ = this.store.select(getApproverProjectLoading);
    this.savingProjectsToDB$ = this.store.select(getProjectLoading);
    this.selectedClient$ = this.store.select(getSelectedClient);
    this.selectedClient$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((client) => {
      this.selectedClient = client;
    });
    if (this.singleCartwheelProjectToLink) {
      const formControls = [].concat([
        new UntypedFormGroup({
          name: new UntypedFormControl(this.singleCartwheelProjectToLink.projectName),
          id: new UntypedFormControl(this.singleCartwheelProjectToLink.projectId),
          externalProjectId: new UntypedFormControl(-1),
          project: new UntypedFormControl(this.singleCartwheelProjectToLink)
        })
      ]);
      this.cartwheelProjectLinkForm.get('projects').setValue(formControls);
    } else {
      this.cartwheelProjects$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((projects) => {
        if (projects) {
          const formControls = projects.filter(p =>
            p.clientId === this.selectedClient.clientID
          ).map(p => new UntypedFormGroup({
            name: new UntypedFormControl(p.projectName),
            id: new UntypedFormControl(p.projectId),
            externalProjectId: new UntypedFormControl(-1),
            project: new UntypedFormControl(p)
          }));
          this.cartwheelProjectLinkForm.get('projects').setValue(formControls);
        }
      });

      this.projects$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((projects) => {
        if (projects) { this.setProjectForm(projects, this.externalImportProjectForm) }
      });

      this.loadingProjectsForImport$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((loading) => {
        this.loadingProjectsForImport = loading;
      });
    }


    this.savingProjectsToDB$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((loading) => {
      if (loading === SuccessStatus.Success) {
        this.close.emit();
      }
    });
  }

  goToCartwheelLinkSlide() {
    if (ApplicationTypes[this.provider] === ApplicationTypes.Harvest) {
      this.setProjectForm(this.clientToImport.projects, this.externalImportProjectForm);
      this.loadingProjectsForImport = null;
    }

    if (ApplicationTypes[this.provider] === ApplicationTypes.Toggl) {
      this.setProjectForm(this.clientToImport.projects, this.externalImportProjectForm);
      this.loadingProjectsForImport = null;
    }

    if (this.cartwheelProjectLinkForm.get('projects').value.length < 1) {
      this.goToProjectImportSlide();
    } else {
      this.currentSlide = 1;
    }
  }

  goToProjectImportSlide() {
    if (this.singleCartwheelProjectToLink) {
      this.save();
    } else {
      this.currentSlide = 2;
    }
  }

  goToClientChoiceSlide() {
    this.currentSlide = 0;
  }

  setProjectForm(projects: Project[], form: UntypedFormGroup) {
    form.get('projects').setValue(
      projects.map((project) => {
        const modifiedProject = Object.assign({}, project, {
          clientId: this.selectedClient.clientID
        });
        const externalId =
          (ApplicationTypes[this.provider] === ApplicationTypes.Harvest) ?
            [modifiedProject.harvestProjectId, modifiedProject.harvestTaskId, modifiedProject.harvestAccountId].join(':') :
            (ApplicationTypes[this.provider] === ApplicationTypes.Toggl) ?
              modifiedProject.togglProjectId :
              modifiedProject.adpProjectId;

        return new UntypedFormGroup({
          name: new UntypedFormControl(modifiedProject.projectName),
          action: new UntypedFormControl(ProjectImportAction.DontImport),
          id: new UntypedFormControl(modifiedProject.projectName + modifiedProject.clientId),
          externalProjectId: new UntypedFormControl(externalId),
          project: new UntypedFormControl(modifiedProject)
        });
      })
    );
  }

  save() {
    const projectsToImport = [];
    (<Array<UntypedFormGroup>>this.externalImportProjectForm.get('projects').value).forEach((project) => {
      if (project.get('action').value === ProjectImportAction.Import) {
        projectsToImport.push(project.get('project').value);
      }
    });

    const updatedCartwheelProjects = (<Array<UntypedFormGroup>>this.cartwheelProjectLinkForm.get('projects').value)
      .filter(project => project.get('externalProjectId').value !== -1)
      .map(project => {
        const p = Object.assign({}, <Project>project.get('project').value);
        if (ApplicationTypes[this.provider] === ApplicationTypes.Harvest) {
          const ids = project.get('externalProjectId').value.split(':');
          p.harvestProjectId = ids[0];
          p.harvestTaskId = ids[1];
          p.harvestAccountId = ids[2];
          p.appType = ApplicationTypes.Harvest;
        } else if (ApplicationTypes[this.provider] === ApplicationTypes.Toggl) {
          p.togglProjectId = project.get('externalProjectId').value;
          p.appType = ApplicationTypes.Toggl;
        } else {
          p.adpProjectId = project.get('externalProjectId').value;
          p.appType = ApplicationTypes.ADP;
        }
        return p;
      });

    this.store.dispatch(new BatchAddProjectAction(projectsToImport));
    this.store.dispatch(new BatchUpdateProjectAction(updatedCartwheelProjects));
  }
}
