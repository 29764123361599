<div class="confirm-dialog">
  <h3 class="header-text">Logout of Cartwheel?</h3>
  <div class="dialog-content">
    <cartwheel-button
      class="w-full max-w-150"
      type="flat-button"
      color="lighterGray"
      label="Yes"
      (onClick)="signOut()"
    />
    <cartwheel-button
      class="w-full max-w-150"
      type="flat-button"
      color="secondary"
      label="No"
      (onClick)="closeDialog()"
    />
  </div>
  <cartwheel-icon-button
    class="dialog-close-btn"
    matIconName="clear"
    color="darkGray"
    (onClick)="closeDialog()"
  />
</div>