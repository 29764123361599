import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Component, Inject, EventEmitter, DestroyRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SuccessStatus } from 'app/shared/enums';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UUID } from 'angular2-uuid';
import { SaveButtonComponent } from 'app/shared/components/save-button/save-button.component';

interface TogglWorkspaceData {
  workspaces: [{
    id: number;
    name: string;
  }],
  body: {
    username: string;
    password: string;
    userId: UUID;
    companyId: UUID;
  }
}

@Component({
  standalone: true,
  selector: 'app-toggl-workspace-dialog',
  templateUrl: './toggl-workspace-dialog.component.html',
  styleUrls: ['./toggl-workspace-dialog.component.scss'],
  imports: [AsyncPipe, FormsModule, SaveButtonComponent, MatRadioModule]
})

export class TogglWorkspaceDialogComponent {
  public selectedWorkspace: { id: number, name: string };
  public close = new EventEmitter<void>();
  public loading$ = new BehaviorSubject<SuccessStatus | boolean>(false);

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: TogglWorkspaceData,
    private destroyRef: DestroyRef
  ) { }

  save() {
    this.loading$.next(SuccessStatus.IsLoading);

    const url = `${environment.server}/ExternalAuth/SelectTogglWorkspace`;
    this.http.post<string>(url, { ...this.data.body, orgId: this.selectedWorkspace.id }).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(() => {
        this.loading$.next(SuccessStatus.Success);
        setTimeout(() => this.close.emit(), 1000);
      }),
      catchError(() => {
        this.loading$.next(SuccessStatus.Error);
        setTimeout(() => this.loading$.next(false), 2000);
        return EMPTY;
      })
    ).subscribe();
  }
}
